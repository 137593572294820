import { Link } from "@/i18n/i18n-navigation";
import * as React from "react";

interface CollectionLinkProps {
  handle: string;
  children?: React.ReactNode;
}

export const CollectionLink: React.FC<CollectionLinkProps> = ({
  handle,
  children,
}) => {
  return <Link href={`/product/${handle}`}>{children}</Link>;
};
