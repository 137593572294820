import dynamic from "next/dynamic";

export const LazySelect = dynamic(() => import("react-select"));

export const LazySelectControl = dynamic(() =>
  import("react-select").then((mod) => mod.components.Control)
);

export const LazySelectValueContainer = dynamic(() =>
  import("react-select").then((mod) => mod.components.ValueContainer)
);
